import IMask from './imask.min.js';
import { closeModals, openModal } from './modal.js'; // функция для открытия модальных окон

/*
* Обработка ввода номера в поля формы
*/
const maskOptions = {
  mask: '+{7}(000)000-00-00',

  prepare: function (appended, masked) {
    if (appended === '8' && masked.value === '') {
      return '';
    }
    return appended;
  },
};

const elements = document.querySelectorAll('input[type="tel"]');
elements.forEach(e => {
  IMask(e, maskOptions);
})

/*
* Обработка формы и отправка в TELEGRGAM
*/
const forms = document.querySelectorAll('form')
forms.forEach(el => {
  el.addEventListener('submit', async (e) => {
    e.preventDefault();

    const inputName = e.target.elements[0].value.toString();
    const inputPhone = e.target.elements[1].value.toString();
    if(inputName.length < 2 || inputPhone.length != 16) {
      alert('Некорректно заполнена форма')
      return false
    }

    const url = "https://hook.eu2.make.com/9xdrf3g8s1ope7liw9qm41tnhzqjp5ed";

    sendForm(url, inputName, inputPhone)
      .then((data) => {
        closeModals()
        console.log('goooooood');
        window.location.href = "https://vk.com/autoschool34";
        // openModal(5)
      })
      .catch(er => alert('Ошибка отправки данных\n' + er))
  })
})

async function sendForm(url, name, phone) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name: name,
      surname: '-',
      phone: phone,
      RoomId: 2576,
      channel: 10438,
      source: 'autosprintshool.ru'
    })
  })
  return res
}